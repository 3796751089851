var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hSt8JpNoHp14oeH_7z6Fe"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://5a1ad4885f6d4d39bacfede9fe795086@o4504685188481024.ingest.sentry.io/4504685191168000",
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
  ],
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name == "GET /api/health") {
      return 0.0 // never send transactions with name GET /api/health
    }
    return 0.7 // sampling for all other transactions
  },
  environment: process.env.SENTRY_ENV,
  enabled: process.env.NODE_ENV !== "development",
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null
    }
    return event
  },
})
